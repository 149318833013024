.about {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 600px;
  cursor: none;
  background-color: #303030;

}

@media (max-width: 1919px) {
  .about {
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .about {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .about {
    height: 703px;
  }
}

.about-wrapper {
  height: 600px;
  width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  cursor: none;

}


@media (max-width: 1919px) {
  .about-wrapper {
    width: 1060px;
    height: 500px;
  }
}

@media (max-width: 1365px) {
  .about-wrapper {
    width: 700px;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .about-wrapper {
    width: 328px;
    height: 500px;
    display: block;
    margin-top: -60px;
  }
}

.about-title {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  color: #FDFDFD;
  margin: 151px 0 100px 0;
}

@media (max-width: 1919px) {
  .about-title {
    margin: 130px 0 72px 0;
  }
}

@media (max-width: 1365px) {
  .about-title {
    font-size: 36px;
    line-height: 49px;
    margin: 70px 0 50px 0;
  }
}

@media (max-width: 767px) {
  .about-title {
    margin: 60px 0 50px 0;
    padding-top: 50px;
  }
}

.about-text {
  width: 530px;
  height: 112px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #FDFDFD;
}

@media (max-width: 1919px) {
  .about-text {
    font-size: 16px;
    line-height: 140%;
    width: 430px;
  }
}

@media (max-width: 1365px) {
  .about-text {
    font-size: 16px;
    line-height: 140%;
    width: 310px;
  }
}

@media (max-width: 767px) {
  .about-text {
    width: 318px;
  }
}

.about-link {
  display: none;
}

@media (max-width: 767px) {
  .about-link {
    display: block;
    font-family: "Satoshi-Regular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #FDFDFD;
    margin-top: 50px;
  }
}

.about-link-svg {
  margin-left: 5px;
}

.about-inner {
  overflow: hidden;
  margin-top: 232px;
  width: 600px;
  height: 210px;
}

@media (max-width: 1919px) {
  .about-inner {
    width: 450px;
    height: 178px;
    margin-top: 211px;
    margin-right: 50px;
  }
}

@media (max-width: 1365px) {
  .about-inner {
    width: 270px;
    height: 123px;
    margin: 0;
    margin-top: 170px;
  }
}

@media (max-width: 767px) {
  .about-inner {
    display: none;
  }
}

@media (min-width: 768px) {
  .about-inner-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .about-inner-mobile {
    margin-top: -20px;
  }
}

.about-card-x {
  width: 515px;
  height: 210px;
  padding-left: 15px;
  animation-name: about-card-animation;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@keyframes about-card-animation {
  0% {
    transform: translateY(-300px);
    opacity: 0;
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 1919px) {
  .about-card-x {
    width: 400px;
    height: 178px;
    padding: 0;
  }
}

@media (max-width: 1365px) {
  .about-card-x {
    width: 250px;
    height: 123px;
  }
}

@-webkit-keyframes box {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -540px);
  }
}

@keyframes box {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -540px);
  }
}

.card-title {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  color: #28C0E2;
}

@media (max-width: 1919px) {
  .card-title {
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 1365px) {
  .card-title {
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 767px) {
  .card-title {
    font-size: 16px;
    line-height: 140%;
  }
}

.card-text {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 150px;
  line-height: 203px;
  color: #FDFDFD;
}

@media (max-width: 1919px) {
  .card-text {
    font-size: 110px;
    line-height: 149px;
    margin-top: 5px;
  }
}

@media (max-width: 1365px) {
  .card-text {
    font-size: 70px;
    line-height: 95px;
  }
}

@media (max-width: 767px) {
  .card-text {
    font-size: 60px;
    line-height: 81px;
  }
}

.card-text-revenue {
  font-family: "Satoshi-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 100px;
  line-height: 203px;
  color: #FDFDFD;
}

@media (max-width: 1919px) {
  .card-text-revenue {
    font-size: 110px;
    line-height: 149px;
    margin-top: 5px;
  }
}

@media (max-width: 1365px) {
  .card-text-revenue {
    font-size: 70px;
    line-height: 95px;
  }
}

@media (max-width: 767px) {
  .card-text-revenue {
    font-size: 60px;
    line-height: 81px;
  }
}


.about-cursor {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  transform: translateX(-50%) translateY(-50%);
  visibility: hidden;
  cursor: none;
}

@media (max-width: 1280px) {
  .about-cursor {
    display: none;
  }
}

.about-cursor.active {
  visibility: visible;
  cursor: none;
}

.cursor-start {
  animation-name: cursor-start;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@keyframes cursor-start {
  0% {
    width: 0px;
    height: 0px;
  }

  100% {
    width: 170px;
    height: 170px;
  }
}